import * as React from "react";
import {graphql, PageProps} from "gatsby";

import Layout from "../components/layout";
import Hero from '../components/hero';
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import ProductsFilter from "../components/products-filter";
import TemplateHead from "../components/template-head";

const products = require('../../src/data/fnx_products_uk.json');

type DataProps = {
  strapiAdvisor: any,
}

export default function ProductCategory({location, pageContext, data: {strapiAdvisor}}: PageProps<DataProps>) {
  const advisor = strapiAdvisor

  const productCategories = products.object.productMasterData.productCategories
  const allowedCategories = [{id: 107, slug: "flowers"}, {id: 101, slug: "coffins-and-caskets"}, {
    id: 117,
    slug: "headstone"
  }, {id: 115, slug: "urns"}]
  const emptyCategory = ["flat", "hearts-and-cushions", "service-arrangements", "special-tributes", "sympathy-flowers", "other", "warehouse", "coffins", "casket-sprays", "urns-urns", "scattering-tubes"]

  const parentCategories = productCategories.filter((el) => {
    return allowedCategories.some((f) => {
      return f.id === el.id && el.parentId === null;
    });
  });

  const currentParentCategoryId = pageContext?.parentId != null ? pageContext?.parentId : pageContext?.id

  const childCategories = productCategories.filter((el) => {
    return parentCategories.some((f) => {
      return f.id === el.parentId && el.parentId != null;
    });
  });

  parentCategories.forEach(object => {
    object.childCategories = childCategories.filter(function (el) {
      return el.parentId === object.id && !emptyCategory.includes(el.slug);
    });
  });

  const hasChildCategory = childCategories.filter(function (el) {
    return el.parentId === currentParentCategoryId && !emptyCategory.includes(el.slug);
  });

  hasChildCategory.forEach(object => {
    object.parentCategory = parentCategories.filter(function (el) {
      return el.id === object.parentId;
    });
  });

  const currentParentCategory = parentCategories.filter(function (el) {
    return el.id === currentParentCategoryId;
  });

  const allProducts = products.object.products

  allProducts.forEach(object => {
    object.parentCategory = parentCategories.filter(function (el) {
      return el.id === object.category.parentId;
    });
  });

  const productChildCat = allProducts.filter((el) => {
    return childCategories.some((f) => {
      return f.id === el.categoryId && el.parentIds.length === 0;
    });
  });

  const productParentCat = allProducts.filter((el) => {
    return parentCategories.some((f) => {
      return f.id === el.categoryId && el.parentIds.length === 0;
    });
  });

  const currentCombinedList = hasChildCategory.concat(currentParentCategory);

  const productParentList = productChildCat.concat(productParentCat).sort((t1, t2) => {
    const product1 = t1.type === 2  && t1.priceRange !== null? Number(t1.priceRange.split("-")[0]) : Number(t1.priceRange);
    const product2 = t2.type === 2  && t2.priceRange !== null? Number(t2.priceRange.split("-")[0]) : Number(t2.priceRange);
    if (product1 > product2) {
      return 1;
    }
    if (product1 < product2) {
      return -1;
    }
    return 0;
  });

  const productListByCategoryChild = productParentList.filter(function (el) {
    return el.categoryId === pageContext?.id;
  });

  const productListByCurrentCombined = productParentList.filter((el) => {
    return currentCombinedList.some((f) => {
      return f.id === el.categoryId;
    });
  });

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--products pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        altImages="Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={pageContext.childName && pageContext.childSlug ? [{
          name: 'Fenix Home',
          slug: '/'
        }, {name: 'Products', slug: '/products/'}, {
          name: pageContext.name,
          slug: '/products/' + pageContext?.slug + '/'
        }, {
          name: pageContext.childName,
          slug: '/products/' + pageContext?.slug + '/' + pageContext?.childSlug + '/'
        }] : [{name: 'Fenix Home', slug: '/'}, {name: 'Products', slug: '/products/'}, {
          name: pageContext.name,
          slug: '/products/' + pageContext?.slug + '/'
        }]}
      >
      </Hero>
      <ProductsFilter
        currentParentCategory={currentParentCategoryId}
        hasChildCategory={hasChildCategory}
        parentCategories={parentCategories}
        productList={Object.keys(pageContext).length != 0 && pageContext && pageContext?.parentId != null ? productListByCategoryChild : Object.keys(pageContext).length != 0 && pageContext?.parentId === null ? productListByCurrentCombined : productParentList}
        pageContextData={pageContext}
        advisorData={advisor}
        prevLocation={location.state ? location.state : null}
      />
      <TrustpilotCarousel/>
    </Layout>
  )
}

export function Head(pageContext) {
  const pageTitle = pageContext.pageContext.childName ? pageContext.pageContext.childName + " - Fenix Funeral Directors" : pageContext.pageContext?.name ? pageContext.pageContext?.name + " - Fenix Funeral Directors" : "Products - Fenix Funeral Directors"
  const pageUrl = pageContext.pageContext.childSlug ? "https://fenixfuneral.co.uk/products/" + pageContext.pageContext.slug + "/" + pageContext.pageContext.childSlug + "/" : pageContext.pageContext.slug ? "https://fenixfuneral.co.uk/products/" + pageContext.pageContext.slug + "/" : "https://fenixfuneral.co.uk/products/"
  return (
    <TemplateHead
      title={pageTitle}
      shortlink={pageUrl}
      alternate={pageUrl}
      canonical={pageUrl}
      og={{
        url: pageUrl,
        title: pageTitle,
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Products","item": "https://fenixfuneral.co.uk/products/"
            },
            ${pageContext.pageContext.childSlug ?
        `
            {
            "@type": "ListItem",
            "position": "3",
            "name": "${pageContext.pageContext?.name}","item": "https://fenixfuneral.co.uk/products/${pageContext.pageContext?.slug}/"
            },
            {
            "@type": "ListItem",
            "position": "4",
            "name": "${pageContext.pageContext?.childName}","item": "https://fenixfuneral.co.uk/products/${pageContext.pageContext?.slug}/${pageContext.pageContext?.childSlug}/"
            }
            ` :
        `{
              "@type": "ListItem",
              "position": "3",
              "name": "${pageContext.pageContext?.name}","item": "https://fenixfuneral.co.uk/products/${pageContext.pageContext?.slug}/"
            }`
      }
          ]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 400, height: 400, placeholder: NONE)
                    }
                }
            }
        }
    }
`;